import { Instance, onSnapshot, types, flow } from 'mobx-state-tree'
import { createContext, useContext } from 'react'
import html2canvas from 'html2canvas'

const Editor = types
  .model({
    code: types.string,
  })
  .actions((self) => ({
    setCode: (code: string) => {
      self.code = code
    },
  }))

const RootStore = types
  .model({
    editor: Editor,
  })
  .actions((self) => ({
    takeScreenshot: flow(function* () {
      const elPreview = document.querySelector('#preview')
      if (!elPreview) {
        return
      }
      const canvas = yield html2canvas(elPreview as HTMLElement, {
        backgroundColor: null,
        windowWidth: elPreview.scrollWidth,
        windowHeight: elPreview.scrollHeight,
      })
      let generated = document.querySelector('#generated')
      if (!generated) {
        return
      }
      generated.textContent = ''
      generated.appendChild(canvas)
      const png = canvas.toDataURL('image/png')
      console.log(png)
    }),
  }))

let initialState = RootStore.create({
  editor: {
    code: `const Wrapper = ({ children }) => (
  <div style={{
    background: 'papayawhip',
    width: '100%',
    minHeight: '100%',
    padding: '1rem',
    textAlign: 'center'
  }}>
    {children}
  </div>
)
const Title = () => (
  <h3 style={{ color: 'palevioletred', letterSpacing: '12px', fontSize: '60px', fontWeight: 'bold' }}>
    FAV
  </h3>
)

const Favicon = () => (
  <Wrapper>
    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
    <Heroicons.Solid.HeartIcon style={{ width: '120px', color: 'palevioletred'}}/>
    </div>
    <Title />
  </Wrapper>
)`,
  },
})

if (!!localStorage) {
  const data = localStorage.getItem('rootState')
  if (data) {
    const json = JSON.parse(data)
    if (RootStore.is(json)) {
      initialState = RootStore.create(json)
    }
  }
}

export const store = initialState

onSnapshot(store, (snapshot) => {
  localStorage.setItem('rootState', JSON.stringify(snapshot))
})

export type RootInstance = Instance<typeof RootStore>
const RootStoreContext = createContext<null | RootInstance>(null)

export const Provider = RootStoreContext.Provider
export function useMst() {
  const store = useContext(RootStoreContext)
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider')
  }
  return store
}
