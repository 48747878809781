import React from 'react'
import './App.css'

import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live'
import * as HeroIconsOutline from '@heroicons/react/outline'
import * as HeroIconsSolid from '@heroicons/react/solid'
import dracula from 'prism-react-renderer/themes/dracula'
import { useMst } from './store'

const scope = {
  Heroicons: {
    Outline: HeroIconsOutline,
    Solid: HeroIconsSolid,
  },
}

function App() {
  const { editor, takeScreenshot } = useMst()

  return (
    <LiveProvider
      code={editor.code}
      scope={scope}
      noInline
      transformCode={(code) => {
        editor.setCode(code)
        return (
          code +
          `
      render(<Favicon />)
      `
        )
      }}
    >
      <div className="w-full h-screen flex flex-row divide-x">
        <div className="w-3/6 flex flex-col">
          <p
            style={{
              marginTop: '20px',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            <b>Definition</b>
          </p>
          <p style={{ marginTop: '5px', textAlign: 'center' }}>
            Define a `Favicon` component that renders your favicon
          </p>
          <LiveEditor className="flex-grow" theme={dracula} />
          <LiveError />
        </div>
        <div className="flex-col w-3/6">
          <p
            style={{
              marginTop: '20px',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            <b>Preview</b>
          </p>
          <p style={{ marginTop: '5px', textAlign: 'center' }}>
            The view inside the dashed lines will be captured for the favicon.
          </p>
          <div className="flex justify-center">
            <div className="border-dashed border-2 border-slate-700">
              <LivePreview
                style={{
                  width: '256px',
                  overflow: 'clip',
                  height: '256px',
                }}
                id="preview"
              />
            </div>
          </div>

          <button
            className="mt-4 px-4 py-2 font-semibold text-sm bg-white dark:bg-gray-700 text-gray-700 dark:text-white border border-gray-300 dark:border-gray-600 hover:border-indigo-300 dark:hover:border-gray-400 rounded-md shadow-sm"
            onClick={() => takeScreenshot()}
          >
            Generate
          </button>

          <div id='generated' className="flex justify-center">

          </div>
        </div>
      </div>
    </LiveProvider>
  )
}

export default App
